<template>
  <button class="site-button" :type="type" :disabled='disabled'>
    <slot></slot>
  </button>
</template>

<script>
export default {
  props:{
    type:{
      type: String,
      default: 'button'
    },
    disabled:{
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
@import "../stylesheets/colors.scss";
@import "../stylesheets/fonts.scss";
.site-button{
    padding: 17px 50px;
    border-radius: 30px;
    background: $secondary;
    @extend .h4;
    color: $light;
    border: none;
    text-transform: uppercase;
    &[disabled]{
      opacity: .5;
      cursor: not-allowed;
    }
}
</style>