<template>
  <div id="app">
    <site-navigation />
    <main>
      <section class="container-fluid hero" id="about">
        <div class="container">
          <div class="row">
            <div class="col-md-7 d-flex flex-column justify-content-center">
              <h1 class="text--color-light my-5">
                Full-cycle custom mobile and web application development
              </h1>
            </div>
            <div
              class="col-md-4 offset-md-1 d-flex flex-column justify-content-center"
            >
              <site-contact-form />
            </div>
          </div>
        </div>
      </section>
      <section class="container site-section">
        <div class="row">
          <div class="col-12">
            <h2
              class="site-section__headline text--with-divider"
              data-aos="fade-up"
            >
              Our Approach <span class="divider"></span>
            </h2>
          </div>
        </div>
        <div class="row justify-content-center align-items-stretch">
          <div class="col-md-4 mb-5">
            <div
              class="card card--elevation text-center h-100"
              data-aos="fade-up"
            >
              <div class="card__icon card__icon--absolute">
                <img
                  class="img-fluid"
                  src="@/assets/Transparent-process.png"
                  alt=""
                />
              </div>
              <h4>Transparent process</h4>
              <p class="mb-0">
                Our relationship with clients is built on frequent communication
                and openness. You get full access to source code and project
                management tools from day one. On top of that, we will meet with
                you regularly to discuss progress and goals, so that you are
                never left in the dark.
              </p>
            </div>
          </div>
          <div class="col-md-4 mb-5 ms-md-4">
            <div
              class="card card--elevation text-center h-100"
              data-aos="fade-up"
            >
              <div class="card__icon card__icon--absolute">
                <img
                  class="img-fluid"
                  src="@/assets/Milestone-based-payments.png"
                  alt=""
                />
              </div>
              <h4>Milestone-based payments</h4>
              <p class="mb-0">
                We minimize your risk by offering a miliestone-based payment
                schedule. Each project is divided into milestones and you only
                pay for one at a time. This means more piece of mind for you and
                freedom for your budget.
              </p>
            </div>
          </div>
          <div class="w-100"></div>
          <div class="col-md-4 mb-5">
            <div
              class="card card--elevation text-center h-100"
              data-aos="fade-up"
            >
              <div class="card__icon card__icon--absolute">
                <img
                  class="img-fluid"
                  src="@/assets/We-are-flexible.png"
                  alt=""
                />
              </div>
              <h4>We are flexible</h4>
              <p class="mb-0">
                Whether you are a startup or trying to implement a new solution
                for your business, it’s never a smooth process. We get it and we
                are here for you. Our teams are flexible and ready to adjust or
                pivot with you.
              </p>
            </div>
          </div>
          <div class="col-md-4 mb-5 ms-md-4">
            <div
              class="card card--elevation text-center h-100"
              data-aos="fade-up"
            >
              <div class="card__icon card__icon--absolute">
                <img class="img-fluid" src="@/assets/Support.png" alt="" />
              </div>
              <h4>Support</h4>
              <p class="mb-0">
                We continue to take care of you after the project is finished.
                We offer support services for each project to keep your new web
                or mobile application updated and running at its best.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="container-fluid site-section site-section--primary pt-0">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2
                class="site-section__headline text--with-divider text--half-height"
                data-aos="fade-up"
              >
                Our Process <span class="divider"></span>
              </h2>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <h3 data-aos="fade-up">Idea review and solutioning</h3>
              <ul class="paragraph mb-5" data-aos="fade-up">
                <li>Review your project and goals</li>
                <li>Provide a quote and timelines</li>
                <li>Help with strategy development</li>
                <li>Define methodologies and approach</li>
              </ul>
              <h3 data-aos="fade-up">Development</h3>
              <ul class="paragraph mb-5" data-aos="fade-up">
                <li>Design the solution (Creative and architecture)</li>
                <li>
                  Provide detailed schedule for each phase of the development
                  process
                </li>
                <li>Build and test you application</li>
              </ul>
              <h3 data-aos="fade-up">Support</h3>
              <ul class="paragraph" data-aos="fade-up">
                <li>Provide maintenance and support for your application</li>
                <li>Add updates and enhancements</li>
              </ul>
            </div>
            <div class="col-md-6">
              <img
                src="@/assets/phones.png"
                alt=""
                class="img-fluid"
                data-aos="fade-up"
              />
            </div>
          </div>
        </div>
      </section>
      <section class="container site-section">
        <div class="row">
          <div class="col-12">
            <h2
              class="site-section__headline text--with-divider"
              data-aos="fade-up"
            >
              What We Offer <span class="divider"></span>
            </h2>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-3 mb-5">
            <div
              class="card card--elevation card--horizontal align-items-center text-left px-2"
              data-aos="fade-up"
            >
              <div class="card__icon">
                <img
                  class="img-fluid"
                  src="@/assets/what-we-offer-icons/1.png"
                  alt=""
                />
              </div>
              <h3 class="mb-0 ms-3 medium">SaaS</h3>
            </div>
          </div>
          <div class="col-md-3 mb-5">
            <div
              class="card card--elevation card--horizontal align-items-center text-left px-2"
              data-aos="fade-up"
            >
              <div class="card__icon">
                <img
                  class="img-fluid"
                  src="@/assets/what-we-offer-icons/2.png"
                  alt=""
                />
              </div>
              <h3 class="mb-0 ms-3 medium">Ecommerce</h3>
            </div>
          </div>
          <div class="col-md-3 mb-5">
            <div
              class="card card--elevation card--horizontal align-items-center text-left px-2"
              data-aos="fade-up"
            >
              <div class="card__icon">
                <img
                  class="img-fluid"
                  src="@/assets/what-we-offer-icons/3.png"
                  alt=""
                />
              </div>
              <h3 class="mb-0 ms-3 medium">Strategies</h3>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-3 mb-5">
            <div
              class="card card--elevation card--horizontal align-items-center text-left px-2"
              data-aos="fade-up"
            >
              <div class="card__icon">
                <img
                  class="img-fluid"
                  src="@/assets/what-we-offer-icons/4.png"
                  alt=""
                />
              </div>
              <h3 class="mb-0 ms-3 medium">Machine Learning</h3>
            </div>
          </div>
          <div class="col-md-3 mb-5">
            <div
              class="card card--elevation card--horizontal align-items-center text-left px-2"
              data-aos="fade-up"
            >
              <div class="card__icon">
                <img
                  class="img-fluid"
                  src="@/assets/what-we-offer-icons/5.png"
                  alt=""
                />
              </div>
              <h3 class="mb-0 ms-3 medium">Creative and logo</h3>
            </div>
          </div>
          <div class="col-md-3 mb-5">
            <div
              class="card card--elevation card--horizontal align-items-center text-left px-2"
              data-aos="fade-up"
            >
              <div class="card__icon">
                <img
                  class="img-fluid"
                  src="@/assets/what-we-offer-icons/6.png"
                  alt=""
                />
              </div>
              <h3 class="mb-0 ms-3 medium">Data Science</h3>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-3 mb-5">
            <div
              class="card card--elevation card--horizontal align-items-center text-left px-2"
              data-aos="fade-up"
            >
              <div class="card__icon">
                <img
                  class="img-fluid"
                  src="@/assets/what-we-offer-icons/7.png"
                  alt=""
                />
              </div>
              <h3 class="mb-0 ms-3 medium">Mobile and web development</h3>
            </div>
          </div>
          <div class="col-md-3 mb-5">
            <div
              class="card card--elevation card--horizontal align-items-center text-left px-2"
              data-aos="fade-up"
            >
              <div class="card__icon">
                <img
                  class="img-fluid"
                  src="@/assets/what-we-offer-icons/8.png"
                  alt=""
                />
              </div>
              <h3 class="mb-0 ms-3 medium">Process Improvement</h3>
            </div>
          </div>
          <div class="col-md-3 mb-5">
            <div
              class="card card--elevation card--horizontal align-items-center text-left px-2"
              data-aos="fade-up"
            >
              <div class="card__icon">
                <img
                  class="img-fluid"
                  src="@/assets/what-we-offer-icons/9.png"
                  alt=""
                />
              </div>
              <h3 class="mb-0 ms-3 medium">Cloud based architecture</h3>
            </div>
          </div>
        </div>
      </section>
      <section
        class="portfolio container-fluid site-section site-section--primary pt-0"
        id="work"
      >
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2
                class="site-section__headline text--with-divider text--half-height"
                data-aos="fade-up"
              >
                Our Work <span class="divider"></span>
              </h2>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p data-aos="fade-up">
                Our team has over 10 years of experiance in building scalable,
                reliable and feature-packed web and mobile applications.
              </p>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12 mt-5">
            <carousel
              :perPage="1"
              :perPageCustom="[
                [480, 1],
                [768, 2],
                [1280, 3],
              ]"
              :mouse-drag="false"
            >
              <slide v-for="(item, inex, key) in projectsItems" :key="key">
                <site-portfolio-item
                  class="mx-5 h-100"
                  :image="item.image"
                  :title="item.title"
                  :text="item.text"
                />
              </slide>
            </carousel>
          </div>
        </div>
        <div class="container">
          <div class="row mt-5 justify-content-center align-items-center">
            <div class="col-md-2 text-center my-5" data-aos="fade-up">
              <img class="img-fluid" src="@/assets/logo/1.png" alt="" />
            </div>
            <div class="col-md-2 text-center my-5" data-aos="fade-up">
              <img class="img-fluid" src="@/assets/logo/2.png" alt="" />
            </div>
            <div class="col-md-2 text-center my-5" data-aos="fade-up">
              <img class="img-fluid" src="@/assets/logo/3.png" alt="" />
            </div>
            <div class="col-md-2 text-center my-5" data-aos="fade-up">
              <img class="img-fluid" src="@/assets/logo/4.png" alt="" />
            </div>
            <div class="col-md-2 text-center my-5" data-aos="fade-up">
              <img class="img-fluid" src="@/assets/logo/5.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section class="container site-section">
        <div class="row">
          <div class="col-12">
            <h2
              class="site-section__headline text--with-divider"
              data-aos="fade-up"
            >
              Customers Love TechQbe <span class="divider"></span>
            </h2>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-8" data-aos="fade-up">
            <carousel :per-page="1" :mouse-drag="false">
              <slide v-for="(item, inex, key) in reviewsItems" :key="key">
                <div
                  class="site-review-item d-flex flex-column align-items-center justify-content-center text-center"
                >
                  <div class="site-review-item__avatar mb-5">
                    <img
                      :src="require(`@/assets/reviews/${item.image}`)"
                      alt=""
                      class="img-fluid"
                    />
                  </div>
                  <span class="h4 h4--regular">
                    {{ item.text }}
                  </span>
                  <span class="h4"> {{ item.title }}</span>
                </div>
              </slide>
            </carousel>
          </div>
        </div>
      </section>
      <section
        class="portfolio container-fluid site-section site-section--primary pt-0"
        id="contacts"
      >
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2
                class="site-section__headline text--with-divider text--half-height"
                data-aos="fade-up"
              >
                Contact Us <span class="divider"></span>
              </h2>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5">
              <site-contact-form class="mb-5" data-aos="fade-up" />
            </div>
            <div class="col-md-5 offset-md-1 d-flex justify-content-end">
              <img
                src="@/assets/conact_us.png"
                alt=""
                class="img-fluid"
                data-aos="fade-up"
              />
            </div>
          </div>
        </div>
      </section>
    </main>
    <site-footer />
  </div>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";

import SiteNavigation from "@/components/SiteNavigation";
import SiteContactForm from "@/components/SiteContactForm";
import SitePortfolioItem from "@/components/SitePortfolioItem";
import SiteFooter from "./components/SiteFooter.vue";
export default {
  components: {
    SiteNavigation,
    SiteContactForm,
    SitePortfolioItem,
    Carousel,
    Slide,
    SiteFooter,
  },
  data() {
    return {
      projectsItems: [
        {
          image: "1.png",
          title: "Asset Payments ",
          text: `Asset Payments is a payment gateway with integrations to european and US banks. It allows businesses to authorize credit cards or to process direct payments.`,
        },
        {
          image: "2.png",
          title: "Get A gift",
          text: `A mobile platform that allows brands to interact with influencers.`,
        },
        {
          image: "3.png",
          title: "Healino",
          text: `Healino is a healthcare platform for personal nutrition analysis.`,
        },
        {
          image: "4.png",
          title: "‎Prego",
          text: `Restaurant bonuses web and mobile application with complete management and admin functionality.`,
        },
        {
          image: "5.png",
          title: "‎Savva Club",
          text: `Savva Club is a multi-functional mobile app for the restaurant industry. It allows restaurants to offer bonuses, review events, stay in touch with loyal customers and online food ordering.`,
        },
        {
          image: "6.png",
          title: "‎Vechiclio",
          text: `Online platform that allows customers to schedule car service appointments. It provides service station booking management functionality and an admin panel with user roles for car service providers.`,
        },
        {
          image: "7.png",
          title: "‎WorldBites",
          text: `Is a mobile application that allows users to host or find local food events. `,
        },
      ],
      reviewsItems: [
        {
          image: "foto-1.png",
          title: `Nik Mushnikov CEO at Asimica`,
          text: `It was great to work with the whole team. They successfully met all requirements and finished my project on time. The team was responsive  and always available to address our needs. Techqbe also provided support after the project was completed. `,
        },
        {
          image: "foto-2.png",
          title: `Savva Club`,
          text: `Techqbe provided high-quality service, their team is easy to work with and dedicated to the project's success. They made the development process clear and understandable.`,
        },
        {
          image: "foto-3.png",
          title: `Asset Payments`,
          text: `Great attention to detail and ability to work within tight deadlines. From technical design to support, TechQbe was able to provide their expertise and was instrumental in the success of our product.`,
        },
      ],
    };
  },
};
</script>
<style lang="scss">
@import "@/stylesheets/main.scss";
.hero {
  background: url("./assets/coding-man.jpg") no-repeat center/cover;
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  &::before {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 34, 0.65);
    z-index: 0;
  }
  .container > .row {
    min-height: 100vh;
    position: relative;
    z-index: 1;
  }
}
.site-review-item {
  /* &__avatar {
    position: relative;
    width: 95px;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    img {
      position: absolute;
      transform: scale(1.1);
    }
  } */
}

@media screen and (max-width: 768px) {
  html,
  body {
    font-size: 14px;
  }
}
@media screen and (max-width: 576px) {
  html,
  body {
    font-size: 12px;
  }
}
</style>
