<template>
  <div class="site-portfolio-item card card--elevation">
    <div class="site-portfolio-item__image">
      <div class="site-portfolio-item__image-content">
        <img :src="require(`@/assets/portfolio/${image}`)" alt="" />
      </div>
    </div>
    <h3>‎{{title}}</h3>
    <p class="h5">
      {{text}}
    </p>
  </div>
</template>

<script>
export default {
  name: "SitePortfolioItem",
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.site-portfolio-item {
  position: relative;
  padding-top: 220px !important;
  overflow: hidden;
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #ededed;
    &-content {
      position: relative;
      overflow: hidden;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        position: absolute;
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>