<template>
  <footer class="site-footer container-fluid py-4">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <!-- <div class="col-md-2">
          <a href="" class="d-inline-block">
            <img src="@/assets/inst.png" alt="" class="img-fluid" />
          </a>
          <a href="" class="d-inline-block ms-3">
            <img src="@/assets/linkidn.png" alt="" class="img-fluid" />
          </a>
        </div> -->
        <div class="col-12 text-md-end">
          <span class="h4">
            <span class="text--light text--color-light">
              304 S. Jones Blvd #6131 Las Vegas, NV 89107
              <a href="">info@techqbe.com</a>
            </span>
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "SiteFooter",
};
</script>

<style lang="scss">
.site-footer {
  background: linear-gradient(90deg, #151541 1.5%, #383885 100%);
  a {
    color: inherit;
    text-decoration: none;
    display: block;
  }
}
</style>