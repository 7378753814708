import Vue from 'vue'
import App from './App.vue'
// import router from './router'
import store from './store'

import AOS from 'aos'
import 'aos/dist/aos.css'

import VueScrollTo from 'vue-scrollto';

Vue.use(VueScrollTo, {
    container: "body",
    duration: 200,
    easing: "ease",
    offset: -150,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
})


Vue.config.productionTip = false

new Vue({
    // router,
    created() {
        AOS.init()
    },
    store,
    render: h => h(App)
}).$mount('#app')