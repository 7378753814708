<template>
  <div class="site-input" 
  :class="{ 
      'site-input--focused': isFocused,
      'site-input--filled': isFilled,
      'site-input--validation-error': !isValid && isTypeStarted,
      }"
  >
    <label class="site-input__label" for=""> {{label}} </label>
    <input 
    type="text"
    @focus="onFocus"
    @blur="onBlur"
    @input="onChange"
    :value="value"
    />
    <span class="site-input__error">
      {{validationErrorMessage}}
    </span>
  </div>
</template>

<script>
export default {
  name: "SiteInput",
  props:{
      value:{
          type: [String,Number],
          default: ''
      },
      label:{
          type: String,
          default: ''
      },
      rule:{
        type: Function,
        default: ()=> true
      }
  },
  data(){
      return{
          isFocused: false,
          isTypeStarted: false
      }
  },
  methods: {
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    onChange(event) {
        this.$emit('input',event.target.value)
    }
  },
  computed:{
      isFilled(){
          return this.value.length>0
      },
      isValid(){
        return this.rule(this.value) === true
      },
      validationErrorMessage(){
        return !this.isValid ? this.rule(this.value) : false
      }
  },
  watch:{
    'value'(newValue){
      if(newValue.length){
        this.isTypeStarted = true;
      }
    },
    'isValid':{
      immediate: true,
      handler(newValue,oldValue){
        if(newValue !== oldValue){
          this.$emit('validation',newValue)
        }
      }
    }
  },
};
</script>

<style lang="scss">
@import "../stylesheets/colors.scss";
@import "../stylesheets/fonts.scss";
.site-input {
  $base: &;
  position: relative;
  width: 100%;
  margin-top: rem(map-get($fonts-sizes, "sz-5"));
  margin-bottom: rem(map-get($fonts-sizes, "sz-5"));
  &__label {
    position: absolute;
    z-index: 0;
    @extend .h5;
    @extend .text--color-grey;
    transform: translateY(0);
    transition: transform .1s ease-in-out;
  }
  input {
    border: none;
    border-bottom: thin solid $grey;
    width: 100%;
    background: transparent;
    z-index: 1;
    position: relative;
    &:focus,
    &:active {
      outline: none;
    }
  }
  &__error{
    @extend .h5;
    color: red;
    opacity: .0;
  }
  &--focused,&--filled{
      #{$base}__label{
          /* top: 0; */
          /* color: $dark; */
          transform: translateY(-100%);
      }
  }
  &--validation-error{
    #{$base}__label{
      color: red;
       animation: shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
    }
    #{$base}__error{
      opacity: 1;
    }
  }
}
</style>