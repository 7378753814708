<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img class="img-fluid" src="../assets/logo.svg" alt="" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" href="#" v-scroll-to="'#about'">About Us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" v-scroll-to="'#work'">Our Work</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" v-scroll-to="'#contacts'">Contacts</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "SiteNavigation",
};
</script>

<style>
</style>