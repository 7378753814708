<template>
  <form
    action=""
    class="site-contact-form card card--elevation"
    @submit.prevent="isValid && onSubmit()"
  >
    <div v-if="!isFormSentSuccess">
      <span class="h3"> Request a Free Consultation Here </span>
      <site-input
        label="Name*"
        v-model="formData.name"
        :rule="rules.name"
        @validation="onValidation"
      />
      <site-input label="Email*" v-model="formData.email" :rule="rules.email" />
      <site-input
        label="Add additional information"
        v-model="formData.message"
      />
      <site-button class="mt-3" type="submit" :disabled="!isValid">
        {{ isFormLoading ? "Loading..." : "Let's talk" }}
      </site-button>
    </div>
    <div v-else>
      <span class="h3"> Our experts will contact you shortly </span>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import { email, name } from "@/common/rules";
import SiteInput from "@/components/SiteInput";
import SiteButton from "@/components/SiteButton";
export default {
  components: {
    SiteInput,
    SiteButton,
  },
  name: "SiteContactForm",
  data() {
    return {
      formData: {
        name: "",
        email: "",
        message: "",
      },
      rules: {
        email,
        name,
      },
      validationErrors: 0,
      isFormLoading: false,
      isFormSentSuccess: false,
    };
  },
  methods: {
    onValidation(isValid) {
      isValid ? this.validationErrors-- : this.validationErrors++;
    },
    async onSubmit() {
      this.isFormLoading = true;
      try {
        const formDataBody = new FormData();
        formDataBody.append("name", this.formData.name);
        formDataBody.append("email", this.formData.email);
        formDataBody.append("message", this.formData.message);
        const response = await axios.post("/form.php", formDataBody, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (response.status === 200) {
          this.isFormSentSuccess = true;
        }
        // console.log(response.data);
        this.formData = {
          name: "",
          email: "",
          message: "",
        };
      } catch (err) {
        console.error(err);
      } finally {
        this.isFormLoading = false;
      }
    },
  },
  computed: {
    isValid() {
      return this.validationErrors === 0;
    },
  },
};
</script>

<style lang="scss">
.site-contact-form {
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>